<!--
 * @Author: lijun
 * @Date: 2021-11-06 10:44:27
 * @LastEditTime: 2022-08-22 14:28:40
 * @LastEditors: ch3nh2
 * @Description: 消息调度
 * @FilePath: /zhy_dataview_fe/src/pages/NoticeSchedule/Initiate.vue
-->
<template>
  <div class="notice_container">
    <template v-if="flag">
      <div style="
          width: 100%;
          height: 93%;
          bottom: 0px;
          position: absolute;
          background-color: #333;
          filter: Alpha(Opacity=60);
          opacity: 0.6;
          z-index: 9999;
        "></div>
    </template>
    <Navigation />
    <div class="arrow_card" v-loading="loading" element-loading-text="加载中...">
      <b class="b top_left"></b><b class="b top_right"></b><b class="b bottom_left"></b><b class="b bottom_right"></b>
      <div class="card_title">
        <span style="margin-right: 20px; font-size: 18px">消息记录</span>
        <el-form :inline="true" :model="formInline" class="form-inline">
          <el-form-item>
            <el-input placeholder="请输入关键词搜索" v-model="keyword" @keyup.enter="searchEvent" class="search-input"
              clearable>
              <template #prefix>
                <el-icon class="el-input__icon">
                  <search />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker v-model="beginTime" type="date" placeholder="选择日期" value-format="YYYY-MM-DD" />
          </el-form-item>
          <el-form-item label="至" class="item-split"></el-form-item>
          <el-form-item>
            <el-date-picker v-model="endTime" type="date" placeholder="选择日期" value-format="YYYY-MM-DD" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchEvent">查 询</el-button>
          </el-form-item>
        </el-form>
        <div class="card_btn">
          <el-button type="primary" @click="newNotice">发送新通知</el-button>
        </div>
      </div>
      <div class="card_content">
        <div class="table">
          <el-table :data="tableData" height="750">
            <el-table-column show-overflow-tooltip prop="title" label="标题" align="center" width="400" />
            <el-table-column prop="sendTime" label="发送时间" align="center" width="220">
              <template #default="scope">
                <span>{{ scope.row.sendTime || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="attach" label="附件" align="center" width="200">
              <template #default="scope">
                <div v-if="scope.row.attach" class="accessory">
                  <el-icon>
                    <Paperclip />
                  </el-icon>
                </div>
                <span v-else>无</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="content" label="内容摘要" align="center" />
            <el-table-column show-overflow-tooltip prop="recipientUserList" label="发送对象" align="center" />
            <el-table-column label="操作" align="center" width="150">
              <template #default="scope">
                <el-button :class="'view-details'" type="text" @click="viewDetailsEvent(scope.row.id)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pagination">
        <el-pagination v-model="pageNum" :page-size="pageSize" layout="prev, pager, next, jumper" :total="total"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Calendar, Search, Paperclip } from "@element-plus/icons";
import { ElMessage } from "element-plus";
import Navigation from "components/InsidePages/Navigation";
import utils from "@/utils";
import dayjs from "dayjs";

export default {
  name: "noticeInitiate",
  data() {
    return {
      loading: false,
      pageNum: 1,
      pageSize: 30,
      keyword: "",
      total: 0,
      beginTime: "",
      endTime: "",
      tableData: [],

      formInline: {
        user: "",
        region: "",
      },
      flag: true,
    };
  },
  methods: {
    // 查看详情
    viewDetailsEvent(id) {
      this.$router.push({ path: "/notice/details", query: { id } });
    },
    handleSizeChange() { },
    // 点击页码
    handleCurrentChange(cur) {
      this.pageNum = cur;
      this.getListApiEvent();
    },
    newNotice() {
      this.$router.push({ name: "noticeRelease" });
    },
    // 查询事件
    searchEvent() {
      this.getListApiEvent();
    },
    //获取列表数据
    getListApiEvent() {
      this.loading = true
      this.$api
        .getNoticeList({
          type: 'MESSAGE',
          page: this.pageNum,
          pageSize: this.pageSize,
          searchValue: this.keyword,
          beginTime: this.beginTime,
          endTime: this.endTime
        })
        .then((res) => {
          const { data, total } = res;
          const list = [];
          data.forEach((item) => {
            const {
              id,
              title,
              sendTime,
              attach,
              content,
              recipientUserList,
            } = item;
            let rRecipientUserList = [];
            if (recipientUserList && recipientUserList.length > 0) {
              rRecipientUserList = recipientUserList.map((item) => item.userName);
            }
            list.push({
              id,
              title: title.substring(0, 100),
              sendTime,
              attach,
              content: utils.removeHtml(content).substring(0, 100),
              recipientUserList: rRecipientUserList
                .join("、")
                .substring(0, 100),
            });
          });
          this.loading = false;
          this.tableData = list;
          this.total = total;
        });
    },
  },
  mounted() {
    this.getListApiEvent();

    var showflag = localStorage.getItem("ACCESS_PERMISSIONS");
    if (showflag.split(",").length >= 1) {
      if (showflag.split(",").findIndex((item) => item == "b004") >= 1) {
        this.flag = false;
      } else {
        ElMessage.error("暂无权限！");
      }
    } else {
      ElMessage.error("暂无权限！");
    }
  },
  components: {
    Navigation,
    Calendar,
    Search,
    Paperclip,
  },
};
</script>

<style lang="scss" scoped>
.notice_container {
  padding: 10px 0;
}

.arrow_card {
  width: 100%;
  height: 900px;
  background: #202331;

  .card_title {
    margin-bottom: 20px;

    .search-input .el-input__icon {
      line-height: 44px;
    }
  }

  .card_content {
    max-height: 740px;
    overflow: hidden;
  }
}

.search-input {
  width: 400px;
  margin-right: 40px;
}

.form-inline {
  .el-form-item {
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.pagination {
  margin-top: 20px;
}

.table {
  .download {
    color: #3895cd;
    text-decoration: none;
  }

  .accessory {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .view-details {
    color: #d24e50;
  }
}
</style>
