<!--
 * @Author: Leif
 * @Date: 2021-10-25 18:26:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-02-25 15:38:40
 * @FilePath: /zhy_dataview_fe/src/components/InsidePages/Navigation.vue
 * @Description: 内页导航
-->
<template>
  <div class="navigation">
    <el-button class="navigation-btn" @click="goBack">
      <span class="navigation-btn-text">返回主页面</span>
    </el-button>
    <div class="nav_list" v-if="isNavigation">
      <el-button
        type="primary"
        class="navigation-btn"
        v-for="(item, index) in navigationArr"
        :key="index"
        :class="currentRouteName.indexOf(item.name) < 0 ? '' : 'active'"
        @click="goPage(item)"
        >{{ item.title }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { ElIcon } from "element-plus/lib/components/icon";
import { ArrowLeft } from "@element-plus/icons";

export default {
  name: "Navigation",
  data() {
    return {
      navigationArr: [
        {
          name: "meeting",
          title: "会议调度",
          url: "/meeting/initiate",
        },
        {
          name: "monitoring",
          title: "视频监控调度",
          url: "/monitoring/screen",
        },
        {
          name: "notice",
          title: "通知消息",
          url: "/notice/initiate",
        },
      ],
      currentRouteName: this.$route.name,
    };
  },
  computed: {},
  components: {
    ElIcon,
    ArrowLeft,
  },
  props: {
    isNavigation: {
      default: true,
    },
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
    goPage(item) {
      this.$router.push({ path: item.url });
    },
  },
  created() {},
  beforeUnmount() {},
};
</script>

<style lang="scss">
.navigation {
  display: flex;
  align-items: center;
  padding: 0 0 20px 0;
}
.navigation-btn {
  width: 140px;
  height: 44px;
  background: rgba(95, 107, 138, 0.7);
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin-right: 15px;
  color: #fff;
  transition: background 0.2s ease-out;
  &:hover {
    // border: #fff 2px solid;
    background: rgba(95, 107, 138, 1);
  }
  &:active {
    opacity: 0.5;
  }
}
.navigation-btn-text {
}
.active {
  background: rgba(28, 117, 182, 0.7);
  &:hover {
    // border: #fff 2px solid;
    background: rgba(28, 117, 182, 0.7);
  }
}
</style>
